
import {
  Parallax,
  Navigation,
  Pagination,
  EffectFade,
  Autoplay,
  Keyboard,
} from 'swiper/modules'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import Btn from '@/components/Btn'
import utils from '@/mixins/utils'
import HTMLRender from '@/components/HTMLRender'
import 'swiper/swiper.min.css'
import 'swiper/modules/effect-fade.min.css'
import 'swiper/modules/pagination.min.css'
import 'swiper/modules/autoplay.min.css'
import 'swiper/modules/keyboard.min.css'
import 'swiper/modules/parallax.min.css'
import 'swiper/modules/navigation.min.css'
Swiper.use([Pagination])
export default {
  mixins: [utils],
  components: {
    Swiper,
    SwiperSlide,
    Btn,
    HTMLRender,
  },
  directives: {
    swiper: directive,
  },
  setup() {
    return {
      modules: [
        Parallax,
        Navigation,
        Pagination,
        EffectFade,
        Autoplay,
        Keyboard,
      ],
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      swiperOptions: {
        effect: this.data.effect === 'fade' ? 'fade' : null,
        loop: true,
        allowTouchMove: true,
        keyboard: {
          enabled: true,
        },
        parallax: this.data.effect === 'parallax',
        modules: [
          Parallax,
          Navigation,
          EffectFade,
          Pagination,
          Autoplay,
          Keyboard,
        ],
        pagination: { el: '.swiper-pagination', clickable: true },
        autoplay: this.data.autoplay
          ? {
              delay: this.data.delay || 5000,
              disableOnInteraction: false,
            }
          : false,
        speed: this.data.speed || 300,
        breakpoints: {
          1024: {
            allowTouchMove: false,
          },
        },
        navigation: this.data.arrows
          ? {
              nextEl: '#swiper-button-next-' + this._uid,
              prevEl: '#swiper-button-prev-' + this._uid,
            }
          : false,
      },
    }
  },
  methods: {
    getUrl(image, width) {
      if (image && image.attributes.mime === 'image/gif') {
        return image.attributes.url
      } else if (this.$imagekit) {
        return this.$imagekit.url({
          src: image.attributes.url,
          transformation: [
            {
              width,
              quality: '70',
              dpr: 'auto',
              crop: 'fill',
            },
          ],
        })
      } else {
        return ''
      }
    },
  },
}
